<template>
    <div>
        <v-parallax
            src="../assets/images/slide1.jpg"
            :height="($vuetify.breakpoint.smAndUp)?250:180"
        >
            <v-container class="mt-auto">
                <v-row>
                    <v-col cols="12">
                        <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
                            <v-icon :size="($vuetify.breakpoint.smAndUp)?65:45">
                                mdi-account
                            </v-icon> <br>
                            Iniciar sesión
                        </h1>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>

        <v-container class="py-15">
            <v-row>
                <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3">
                    <v-card>
                        <v-card-text>
                            <!-- login -->
                            <app-login @success-login="successLogin"></app-login>
                            <!-- login END -->
                            <div class="text-center py-5">--- ó ---</div>
                            <v-btn
                                block
                                text
                                color="primary"
                                to="/register"
                            >Crear cuenta </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AppLogin from '../views/auth/Login.vue'
export default {
    components: {
        AppLogin
    },
    methods: {
        successLogin() {
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
            } else {
                this.$router.push('/')
            }
            console.log(this.$route);
        }
    },
}
</script>